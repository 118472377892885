@font-face {
font-family: 'MADE';
font-style: normal;
font-weight: 800;
src: url('./fonts/MADE.otf');
}
@font-face {
    font-family: 'MADE EVO';
    font-style: normal;
    font-weight: 800;
    src: url('./fonts/MADE_EVO.otf');
}
@keyframes GradientAnimation {
    0%{background-position:0% 9%}
    50%{background-position:100% 92%}
    100%{background-position:0% 9%}
}
@keyframes Rotating {
    from{transform: rotate(0deg)}
    to{transform: rotate(360deg)}
}
@keyframes bounce {
    50% {
        transform: translateY(-50%);
    }
    100% {
        transform: translateY(0);
    }
}
*{
    box-sizing:border-box;
}
body{
    max-width: 100vw;
    overflow-x: hidden;
    font-family: 'MADE';
}
.App{
    --opacity: 0;
    animation: GradientAnimation 20s ease infinite;
    background-size: 200% 200%;
    background-image: linear-gradient(157deg, rgba(173, 83, 137, var(--opacity)), rgba(60, 16, 83, var(--opacity)));
    background-attachment: fixed;
}
.button{
    color: currentColor;
    text-decoration: none;
    display: inline-block;
    padding: 0.2em 0.5em;
    margin-top: 0.5em;
    border: 1px solid white;
    border-radius: 2px;
    transition:all 0.3s ease-in-out;
}
.button:hover{
    border-radius: 7px;
}
#header{
    --fontSizeMiddle: 1;
    --opacityMiddle: 1;
    --headerTop: 30;
    text-align:center;
    position: fixed;
    z-index:1337;
    width:100%;
    top:calc(var(--headerTop) * 1vh);
}
#header .scrollIndicator{
    position:fixed;
    animation: bounce 5s ease infinite;
    color:#75326e;
    width:100%;
    text-align:center;   
    opacity: 1;
    bottom:20px;
    transition: opacity 1s ease-out, bottom 2s ease-out;
}
.App.scrolled #header .scrollIndicator{
    opacity: 0;
    bottom:-100px;
}
.title{
    font-family: 'MADE';
    font-size: 60px;
    margin:0;
    color:#222;
    margin-right:-0.2em;
}
.title *{
    vertical-align: middle;
}
.title .logo{
    font-family: 'MADE EVO'
}
.title .f{
    position: relative;
    top: 0.03em;
}
.title .logo.dot{
    color:#75326e;
    position:relative;
    left: -0.2em;
    bottom: -0.02em;
    transition:color 0.3s ease-in-out;
}
.App.scrolled #header .logo.dot{
    color:#222;
}
.title .middleTitle{
    font-size:calc(var(--fontSizeMiddle) * 1em);
}
.title .taglineWrap{
    display:block;
    font-size:17px;
    margin: -0.6em 0 0 -0.6em;
}
.title .tagline{
    font-size:calc(var(--fontSizeMiddle) * 1em);
    opacity:calc(var(--opacityMiddle));
}
.hyperdriveScene > *{
    padding:0 10px;
    text-align:center;
    color:white;
    opacity: var(--opacity)
}
.hyperdriveScene .intro{
    color:#222;
    text-shadow:none;
    opacity:1;
}
.hyperdriveScene .intro h3{
    font-size:0.8em;
}
.hyperdriveScene .particles{
    position:absolute;
    max-width: 200vw!important;
    width: 200vw!important;
    height: 200vh!important;
    opacity:1;
}

.hyperdrive .hyperdriveScene .twoColumn{
    display:flex;
    align-items: center;
    flex-direction: column;
}
.hyperdrive .hyperdriveScene .columnText{
    text-shadow: -1px -1px 5px #6b2d6a, 1px -1px 5px #6b2d6a, -1px 1px 5px #6b2d6a, 1px 1px 5px #6B2D6B;
}
.hyperdrive .hyperdriveScene .columnImage{
    position: absolute;
    top: 0;
    transform: translateY(-100%);
}
.perspective img, .uppdrag img{
    max-width:130px;
    filter: brightness(0) invert(1);
    padding:0 10px;
    opacity:0.9;
}
.spiral{
    width:100%!important;
    height:100%;
}
.spiral > *{
    position:absolute;
}
.spiral > *:nth-child(1){
    top:0;
    left:0;
    transform:translateZ(100px)
}

@media only screen and (max-width: 768px) {
    .perspective, .uppdrag{
        top:58%!important;
    }
    .hyperdrive .hyperdriveScene .uppdrag{
        flex-direction: column-reverse;
    }
}
@media only screen and (min-width: 768px) {
    .intro h1{
        font-size:1.8em;
    }
    .hyperdrive .hyperdriveScene .twoColumn{
        flex-direction: row;
        width:350px;
    }
    .hyperdrive .hyperdriveScene .perspective .columnImage{
        top: 50%;
        left: 0;
        transform: translate(calc(-100% + 10px), -50%);
    }
    .hyperdrive .hyperdriveScene .uppdrag .columnImage{
        top: 50%;
        right: 0;
        transform: translate(calc(100% - 10px), -50%);
    }
}